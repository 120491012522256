import { template as template_ae106d2e7a1d46b1901ea8cf29e78ba9 } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SortableColumn from "./sortable-column";
const PostersCell = template_ae106d2e7a1d46b1901ea8cf29e78ba9(`
  <SortableColumn
    @order="posters"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="posters"
    @screenreaderOnly={{true}}
    aria-label={{i18n "category.sort_options.posters"}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostersCell;
