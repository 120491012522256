import { template as template_fa362ee389684ad29da38ce59a146737 } from "@ember/template-compiler";
const FKLabel = template_fa362ee389684ad29da38ce59a146737(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
