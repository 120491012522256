import { template as template_fea58fe2f93c4b3aaef62a6c3f863a2d } from "@ember/template-compiler";
const FKText = template_fea58fe2f93c4b3aaef62a6c3f863a2d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
