import { template as template_d35c3d9ba78245898f37a3b737718477 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d35c3d9ba78245898f37a3b737718477(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
